// @ts-strict-ignore
import type { History } from "history";
import type { ReactNode } from "react";
import { createContext, useContext } from "react";

interface HistoryContextType {
  history: History;
}

const Default: HistoryContextType = {
  history: null,
};

const HistoryContext = createContext<HistoryContextType>(Default);

const useHistory = () => {
  const { history } = useContext(HistoryContext);

  return history;
};

interface Props {
  history: History;
  children: ReactNode;
}

const HistoryProvider = ({ children, history }: Props) => {
  return <HistoryContext value={{ history }}>{children}</HistoryContext>;
};

export { HistoryProvider, useHistory };
export type { HistoryContextType };
